.list {
  width: calc(100%) !important;
  align-self: center !important;
  overflow: hidden;
  flex-wrap: wrap;
}

.badges {
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.load {
  margin: auto;
  color: #222 !important;
  text-decoration: none !important;
}
.load:hover {
  color: #0a58ca !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .list {
    width: calc(2 * 300px + 1rem) !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .list {
    width: calc(3 * 300px + 2rem) !important;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .list {
    width: calc(4 * 300px + 3rem) !important;
  }
  /* Adds semicolon after each item except the last item */
  li:first-child:nth-last-child(n + 5) ~ li::before {
    content: ";";
    margin: 0 0.5em 0 -0.75em;
  }
}
