.item {
  width: 100%;
  height: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-shadow: 0px 2px 4px #222;
}

.item:hover img {
  filter: contrast(1.5);
  transition: filter 0.3s;
  cursor: pointer;
}

.item img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.link {
  width: 100%;
  text-decoration: none;
}
.item h1 {
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  font-size: 24px;
  text-shadow: 0px 2px 4px #000;
  line-height: 1.2;
  text-decoration: none;
  text-align: left;
  padding: 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item h1 span {
  font-size: 0.65em;
  font-weight: 300;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .link {
    width: auto;
  }
  .item {
    width: 300px;
    height: 225px;
  }
}
