.header {
  position: absolute !important;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 2;
  color: #ffffff;
  width: 100% !important;
}

.header {
  width: 100%;
}

.brand img {
  width: 224px;
  max-width: 100%;
}

.nav a {
  color: white !important;
}

.nav a:hover {
  color: #aaa !important;
}

.toggler {
  color: white !important;
  border: none !important;
}

@media (min-width: 500px) {
  .brand img {
    width: auto !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .header {
    width: auto !important;
  }
}
