.badge {
  font-weight: 300 !important;
  font-size: 18px !important;
}

.badge:hover {
  cursor: pointer;
  background: #444 !important;
}

.badge i {
  font-style: normal !important;
}

.badge::selection,
.badge i::selection {
  background: none;
}
