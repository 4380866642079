.hero {
  height: 450px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.hero.placeholder {
  z-index: -1;
  width: 100%;
  background-color: #000;
}

.small {
  height: 300px;
}

.hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  background-color: #222;
}

.hero h1 {
  font-family: Arial, Helvetica, sans-serif;
  z-index: 1;
  color: white;
  font-size: 72px;
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  margin: 0;
  font-weight: 700 !important;
}

.hero h1 span {
  font-size: 190px;
  line-height: 0;
  display: inline-block;
  color: #bdb98b;
}

.hero h2 {
  font-size: 16px;
  text-align: right;
}

.hero svg {
  position: absolute;
  z-index: 1;
  bottom: 0;
}
