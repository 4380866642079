.contact {
  font-size: 20px;
}

.contact h2 {
  font-weight: 300 !important;
}

.alert li {
  list-style-type: initial;
}
