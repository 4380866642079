.adventure {
  font-size: 20px;
}

.adventure h2 {
  font-weight: 300 !important;
}

.load {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}
