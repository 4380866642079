body {
  background: #ffffff;
  font-family: Arial, Helvetica, sans-serif !important;
  margin: 0 !important;
  color: #222 !important;
  max-width: 1920px;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

::selection {
  color: inherit;
  background: rgba(189, 185, 139, 0.5);
}

strong {
  font-weight: 700 !important;
}
