.about {
  font-size: 20px;
}

.about h2 {
  font-weight: 300 !important;
}

.about li {
  list-style-type: initial;
}

.card img {
  aspect-ratio: 3 / 4;
  object-fit: cover;
}
