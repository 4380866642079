.service {
  font-size: 14px;
}

.service h1,
.service h2,
.service h3,
.service h4,
.service h5,
.service p {
  margin: 0;
}

.service .price {
  color: green;
  font-weight: bold;
}

.load {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
}
