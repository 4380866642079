.contact {
  align-items: center !important;
  align-self: center !important;
  max-width: 800px;
  font-weight: 300 !important;
  font-size: 16px;
}

.contact > div {
  flex-direction: column !important;
}

.contact .text {
  text-align: center;
}

.contact .text::after {
  content: "";
  display: block;
  width: 150px;
  height: 15px;
  margin: 15px;
  background: #eaeaea;
  margin-left: auto;
  margin-right: auto;
}

.contact .links {
  text-align: center;
}

.contact .links i {
  min-width: 1.5em;
  display: inline-block;
  text-align: center;
  font-style: normal !important;
}

.social i {
  font-size: 48px;
}

.social .facebook {
  color: #4267b2;
}

.social .instagram {
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 450px) {
  .contact > div:not(:first-child) {
    flex-direction: row !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 800px) {
  .contact .links {
    text-align: left;
  }

  .contact > div:first-child {
    flex-direction: row !important;
  }
}
